<template>
  <section class="header_dark fixed-top">
    <b-navbar>
      <!--<b-navbar-brand class="header_name text-white font-weight-bold" href="#">{{$props.name}}</b-navbar-brand>-->
      <b-navbar-brand class="header_name text-white font-weight-bold" href="#"><img height="40px" src="https://cdn.systemico.net/ducksites/customers/cdalaisla/mheaders/logo_laisla_carro.png" /></b-navbar-brand>
      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav class="nav-pills ">
          <b-nav-item class="nav-element ml-2" v-for="link in $props.links" :key="link.id" :href="link.url">{{link.name}}</b-nav-item>
        </b-navbar-nav>

        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto">
          <b-nav-item-dropdown right no-caret no-flip v-if="$props.showMenuProfile">
            <!-- Using 'button-content' slot -->
            <template #button-content>
              <em>
                <b-avatar badge badge-variant="warning" :src="$props.avatar" rounded="circle" :alt="$props.user"></b-avatar>
              </em>
            </template>
            <b-dropdown-item href="#" @click="profile()">Profile</b-dropdown-item>
            <b-dropdown-item href="#">Configuration</b-dropdown-item>
            <b-dropdown-item href="#" @click="salir()">Sign Out</b-dropdown-item>
          </b-nav-item-dropdown>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </section>
</template>

<script>
export default {
  name: 'HeaderComponent',
  props: {
    name: String,
    icon: String,
    links: Object,
    user: String,
    avatar: String,
    showMenuProfile: Boolean,
    showButtonUpgrade: Boolean
  },
  methods:{
    salir: function () {
      localStorage.clear()
      window.open('/api/glogin/logout', '_self')
    },
    profile: function () {
      this.$bvModal.show("modal-profile")
    }
  },
  beforeMount() {
    if (this.showMenuProfile === undefined){
      this.showMenuProfile = false;
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '../../assets/styles/variables.scss';
  .header_dark{
    /**background: $color-blue--dark;**/
    background: #1e934c;
    padding: 10px;
  }
  .header_name{
    font-weight: 500;
    font-size: 1.4em;
    color:#ffffff;
  }
  .nav-element{
    font-size: 0.9em;
    color:#ffffff;
  }
  .nav-element a {
    color:#f8f8f8 !important;
    font-size: 1em;
    font-weight: 500;
  }
  .nav-element a:hover{
    color:#222222;
  }
</style>
