<template>
  <b-container class="p-0 mt-1 pt-1">
    <b-jumbotron :header="domain" :lead="intro['EN'].title" class="bg-transparent">
      <img class="img-fluid w-50" src="../assets/under_construction_1.svg" fluid alt="Under Construction"/>
      <p>{{intro['EN'].body}}</p>
      <ButtonComponent variant="warning" size="lg" href="https://systemico.net" text="More Info"></ButtonComponent>
    </b-jumbotron>
  </b-container>
</template>

<script>
import ButtonComponent from "@/packages/ducksites.ui/components/buttons/ButtonComponent.vue";

export default {
  name: 'IntroView',
  components: {ButtonComponent},
  props: {
    msg: String
  },
  data () {
    return {
      domain: '',
      intro: {
        ES: {
          title: '🔧 Nuestro sitio web está en construcción',
          body: '¡Estamos trabajando en algo increíble! Vuelve pronto para descubrirlo. 🚀'
        },
        EN: {
          title: '🔧 Our website is under construction',
          body: 'We’re working on something amazing! Check back soon to discover it. 🚀'
        }
      }
    }
  },
  beforeMount () {
    this.domain = location.host
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
h1 {
  margin: 40px 0 0;
  font-size: 3.5rem;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #0b1c98;
  font-size: 1rem;
}
</style>
