<template>
  <div>
    <b-row class="mt-2">
       <b-col >
        <img :src="$props.logo" class="align-self-center p-2" width="50%" /><br>
         <img v-if="$props.logo2" :src="$props.logo2" class="align-self-center p-2" width="50%" />
        <div>
          <div v-for="data in $props.info" :key="data.id" class="text-muted small">
            {{data.value}}
          </div>
        </div>
         <div class="h1 mb-2">
           <a href="https://www.facebook.com/cdalaislaa/"><img src="https://cdn.systemico.net/systemico/icons/icon-facebook--hover.svg" alt="network. networks"></a>
           &nbsp;
           <a href="https://www.linkedin.com/in/cdalaisla/"><img src="https://cdn.systemico.net/systemico/icons/icon-linked--hover.svg" alt="network. networks"></a>
           &nbsp;
           <a href="https://youtube.com/@cdalaisla?si=DNyG6RKN8IsCWf-C"><img src="https://cdn.systemico.net/systemico/icons/icon-youtube--hover.svg" alt="network. networks"></a>
           &nbsp;
           <a href="https://www.instagram.com/cdalaisla/"><img src="https://cdn.systemico.net/ducksites/customers/cdalaisla/mheaders/icon-instagram--hover.svg" width="30px" alt="network. networks"></a>
           &nbsp;
           <a href="https://www.tiktok.com/@cdalaisla"><img src="https://cdn.systemico.net/ducksites/customers/cdalaisla/mheaders/icon-tiktok--hover.svg" width="30px" alt="network. networks"></a>
         </div>
      </b-col>
      <b-col v-for="section in $props.links" :key="section.id">
        <b-list-group class="border-0"  >
          <b-list-group-item class="font-weight-bold border-0 border-bottom text-left">{{section.name}}</b-list-group-item>
          <b-list-group-item class="border-0 text-left" v-for="item in section.items" :key="item.id">
           <a :href="item.url" class="text-dark">{{item.name}}</a>
          </b-list-group-item>
        </b-list-group>
      </b-col>
    </b-row>
    <b-row>

    </b-row>
    <b-nav class="border-top mt-2" align="center" >
      <b-nav-item class="footer_item" :href="$props.url">{{$props.name}} - {{$props.slogan}}</b-nav-item>
    </b-nav>
  </div>
</template>

<script>
  export default {
    name: 'FooterComponent',
    props: {
      name: String,
      url:String,
      slogan:String,
      links: Object,
      logo: String,
      info: Object,
      logo2: String
    }
  }
</script>
<style lang="scss" scoped>
  .footer_item{
    font-size: 0.8em;
  }
  .footer_item a{
    color:#222222;
  }
  .footer_item a:hover{
    color:#006df0;
  }
</style>
