<template>
  <b-button :class="'button--' + $props.variant" :href="$props.href" @click="$emit('click')" style="display: inline;">
    <b-icon class="mr-2" v-if="$props.icon !== undefined && ($props.iconPosition==='start' || $props.iconPosition=== undefined)" :icon="$props.icon"></b-icon>&nbsp;
    <span v-html="$props.text"></span>
    <b-icon class="ml-2" v-if="$props.icon !== undefined && $props.iconPosition==='end'" :icon="$props.icon"></b-icon>&nbsp;
    <div class="point"></div>
  </b-button>
</template>

<script>

export default {
  name: 'ButtonComponent',
  props: {
    variant: String,
    text: String,
    icon: String,
    href: String,
    iconPosition: String // start or end
  }
}
</script>
<style lang="scss" scoped>
  @import "../../variables.scss";
  .button--primary {
    width: max-content;
    min-width: 16rem;
    height: 4rem;
    padding: 1rem 2rem 1rem 2rem;
    margin: 0;
    border: 0.2rem solid $color-blue;
    border-radius: 3.2rem;
    background-color: $color-blue;
    font-family: $font-quicksand;
    font-size: 1rem;
    font-weight: bold;
    color: $color-white;
    line-height: 2rem;
    text-align: center;
    display: block;
    position: relative;

    .point {
        width: 1.2rem;
        min-width: 1.2rem;
        height: 1.2rem;
        border-radius: 50%;
        background-color: $color-blue;
        position: absolute;
        top: 0.7rem;
        right: 0.7rem;
        transition: all 0.3s;
    }

    &:hover {
        box-shadow: 0px 4px 10px #006DF04D;
        background-color: $color-blue--dark;
        color:$color-white;
        border: 0.2rem solid $color-blue--dark;
        .point {
            top: -0.7rem;
            right: -0.7rem;
            background-color: $color-blue--dark;
        }
    }
  }
  /** SMALL SCREEN **/
  @media (max-width: 375px) {
    .button--primary {
      font-size: 1rem;
      min-width: auto;
      padding: 1rem 2rem 1rem 2rem;
    }
  }
  .button--secondary {
    width: max-content;
    min-width: 15rem;
    height: 4rem;
    padding: 1rem 4rem 1rem 4rem;
    margin: 0;
    border-radius: 3.2rem;
    border: 0.2rem solid $color-blue;
    background-color: $color-white;
    font-family: $font-quicksand;
    font-size: 1rem;
    font-weight: bold;
    color: $color-blue;
    line-height: 2rem;
    text-align: center;
    display: block;
    position: relative;

    .point {
      width: 1.2rem;
      min-width: 1.2rem;
      height: 1.2rem;
      border-radius: 50%;
      background-color: $color-white;
      position: absolute;
      top: 0.7rem;
      right: 0.7rem;
      transition: all 0.3s;
    }

    &:hover {
      box-shadow: 0px 4px 10px #006DF04D;
      background-color: $color-blue--dark;
      color: $color-white;
      border: 0.2rem solid $color-blue;

      .point {
        top: -0.7rem;
        right: -0.7rem;
        background-color: $color-blue;
      }
    }
  }
  @media (max-width: 375px) {
    .button--secondary {
      font-size: 1rem;
      min-width: auto;
      padding: 1rem 2rem 1rem 2rem;
    }
  }

  .button--info {
    width: max-content;
    min-width: 15rem;
    padding: 1rem 4rem 1rem 4rem;
    border: 0.2rem solid $color-blue;
    margin: 0;
    border-radius: 3.2rem;
    background-color: $color-celeste;
    font-family: 'Quicksand', sans-serif;
    font-size: 1.5rem;
    font-weight: bold;
    color: #006DF0;
    line-height: 2rem;
    text-align: center;
    display: block;
    position: relative;

    .point {
      width: 1.2rem;
      min-width: 1.2rem;
      height: 1.2rem;
      border-radius: 50%;
      background-color: #006DF0;
      position: absolute;
      top: 0.7rem;
      right: 0.7rem;
      opacity: 0;
      transition: all 0.2s;
    }

    &:hover {
      box-shadow: 0px 4px 10px #006df04d;
      background-color: $color-blue;
      color:$color-white;
      border: 0.2rem solid $color-blue;
      .point {
        top: -0.7rem;
        right: -0.7rem;
        opacity: 1;
      }
    }
  }
  @media (max-width: 375px) {
    .button--info {
      font-size: 1rem;
      min-width: auto;
      padding: 1rem 2rem 1rem 2rem;
    }
  }
  .button--gray {
    width: max-content;
    min-width: 15rem;
    padding: 1rem 2.8rem;
    border: 0;
    margin: 0;
    border-radius: 3.2rem;
    background-color: #DBDEE6;
    font-family: $font-quicksand;
    font-size: 1.5rem;
    font-weight: bold;
    color: $color-white;
    line-height: 2rem;
    text-align: center;
    display: block;
    position: relative;

    .point {
        width: 1.2rem;
        min-width: 1.2rem;
        height: 1.2rem;
        border-radius: 50%;
        background-color: #DBDEE6;
        position: absolute;
        top: 0.7rem;
        right: 0.7rem;
        transition: all 0.3s;
    }

    &:hover {
        box-shadow: 0px 4px 10px #006DF04D;

        .point {
            top: -0.7rem;
            right: -0.7rem;
        }
    }
  }
  @media (max-width: 375px) {
    .button--gray {
      font-size: 1rem;
      min-width: auto;
      padding: 1rem 2rem 1rem 2rem;
    }
  }
</style>
