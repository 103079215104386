<template>
  <div class="home">
    <IntroView msg="Welcome to Your <b>Jumichica Vue.js</b> Initial App"/>
  </div>
</template>
<script>
// @ is an alias to /src
import IntroView from '@/components/IntroView.vue'
export default {
  name: 'Home',
  components: {
    IntroView
  }
}
</script>
